import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../assets/company-logo.png";
import {
    Box,
    IconButton,
    Typography,
    Button,
    Badge,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
    Menu,
    MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import LocationContext from "../../context/LocationContext";
import ErrorContext from "../../context/ErrorContext";
import axiosApiFunction from "../../utils/axiosapifunc";
import EditLocationAltRoundedIcon from "@mui/icons-material/EditLocationAltRounded";
import TableBarIcon from "@mui/icons-material/TableBar";
import { motion } from "framer-motion";
import { clearLocalStorage } from "../../utils/clearlocalstorage";
import "./Header.css";
import { isDeliveryOrder } from "../../redux/slice/CartSlice";

const Header = ({ totalAmt, setShowLocationPopup }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { apiError, setApiError, clearApiError } = useContext(ErrorContext);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery("(max-width: 850px)");

    const getStoreData = useSelector((state) => state.store.storeData);
    const getTotalAmountWithTax = useSelector((state) => state.cart.totalAmountWithTax);
    const orderLevelCharge = useSelector((state) => state.cart.orderLevelCharge);
    const isDeliveryOrderFlag = useSelector((state) => state.cart.isDeliveryOrder);
    const totalItemLevelCharge = useSelector((state) => state.cart.totalItemLevelCharge);
    const locationCtx = useContext(LocationContext);
    const currentStore = locationCtx.storeLocation;
    let totalCartItem = JSON.parse(localStorage.getItem("cartData"))?.length || 0;
    const getTokenData = JSON.parse(localStorage.getItem("tokenData"));
    const getUserData = JSON.parse(localStorage.getItem("userData"));
    const companyId = JSON.parse(localStorage.getItem("companyId"));
    const getTableId = JSON.parse(localStorage.getItem("tblid")) || 0;
    const getTableName = JSON.parse(localStorage.getItem("tblname")) || "";
    const isLocationAvailable = JSON.parse(localStorage.getItem("isLocationAvailable"));
    localStorage.setItem("bopisToken", getTokenData?.token || "");

    useEffect(() => {
        if (getUserData) {
            dispatch(isDeliveryOrder(getUserData.isDeliveryType === 1 ? true : false));
        }
    }, []);

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget); // Open the dropdown menu
    };

    const handleMenuClose = () => {
        setAnchorEl(null); // Close the dropdown menu
    };

    const handleNavigate = () => {
        navigate("/cart");
    };

    const handleLogout = async () => {
        try {
            clearApiError();
            setIsLoading(true);

            const response = await axiosApiFunction(
                "post",
                "/api/v1/logout",
                {},
                getTokenData.token,
                {
                    companyid: companyId,
                    mobileno: Number(getTokenData.data.customerdetails.phoneno),
                }
            );

            if (response.status === 200) {
                clearLocalStorage();               
                const id = localStorage.getItem("encryptedData");
                //navigate(`/login/${id}`);
                navigate("/");
                //localStorage.removeItem("encryptedData");                
            }
        } catch (error) {
            setApiError(error.message);
            clearLocalStorage();

            navigate("/");
        } finally {
            setIsLoading(false);
        }
    };

    const handleOrderHistory = async () => {
        clearApiError();
        setIsLoading(true);
        navigate("/order-history");
    };

    const spring = {
        type: "spring",
        stiffness: 260,
        damping: 20,
    };

    const variants = {
        hover: {
            scale: 1.1,
            transition: spring,
        },
        click: {
            scale: 1.3,
            rotate: 360,
            transition: {
                ...spring,
                delay: 0.2,
            },
        },
    };

    const menuItems = (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            // onKeyDown={toggleDrawer(false)}
        >
            <List>
                {getTokenData && (
                    <ListItem button>
                        <ListItemIcon>
                            <AccountCircleIcon />
                        </ListItemIcon>
                        {/* <ListItemText primary={getTokenData && (getTokenData.data.customerdetails[0].name ? (getTokenData.data.customerdetails[0].name).toUpperCase() : (getUserData.username ? getUserData.username.toUpperCase() :getTokenData.data.customerdetails[0].phoneno))} /> */}
                        <ListItemText
                            primary={
                                getTokenData.data?.customerdetails?.name
                                    ? getTokenData.data.customerdetails.name
                                          .toLowerCase()
                                          .replace(/\b\w/g, (char) => char.toUpperCase())
                                    : getUserData.username
                                          .toLowerCase()
                                          .replace(/\b\w/g, (char) => char.toUpperCase())
                            }
                        />
                    </ListItem>
                )}
                {/* <ListItem button>
                    <ListItemIcon>
                        <LocationOnIcon />
                    </ListItemIcon>
                    <ListItemText primary="Allow location access for local store menu and promos" />
                </ListItem>
                <ListItem button>
                    <Button variant="contained" color="primary">
                        Set Location
                    </Button>
                </ListItem> */}
                <ListItem button>
                    <ListItemIcon>
                        <LocationOnIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={`Pick up at: ${(getStoreData && getStoreData.address) || "NA"}`}
                    />
                </ListItem>
                {location.pathname === "/catalogue" && (
                    <>
                        {!(isLocationAvailable == "true") && (
                            <ListItem button onClick={() => setShowLocationPopup(true)}>
                                <ListItemIcon>
                                    <EditLocationAltRoundedIcon />
                                </ListItemIcon>
                                <ListItemText primary={"Change Store"} />
                            </ListItem>
                        )}
                    </>
                )}
                {getTokenData && (
                    <>
                        <ListItem button onClick={handleOrderHistory}>
                            <ListItemIcon>
                                <HistoryRoundedIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Order History"} />
                        </ListItem>
                        <ListItem button onClick={handleLogout} >
                            <ListItemIcon>
                                <LogoutRoundedIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Logout"} />
                        </ListItem>
                    </>
                )}

                {/* <ListItem button>
                    <Button variant="contained" color="primary">
                        Set Location
                    </Button>
                </ListItem> */}

                {/* <ListItem button>
          <ListItemText primary="₹891.90" />
        </ListItem> */}
                {/* <ListItem button>
          <ListItemIcon>
            <Badge badgeContent={5} color="secondary">
              <ShoppingCartIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary="Cart" />
        </ListItem> */}
            </List>
        </Box>
    );

    let totalToPay = parseFloat(
        (
            Number(getTotalAmountWithTax) +
            (getTableId == 0 ? Number(totalItemLevelCharge) : 0) +
            (isDeliveryOrderFlag && getTableId == 0 ? Number(orderLevelCharge) : 0)
        ).toFixed(2)
    );

    return (
        <header className="header">
            <div className="header-left">
                {isMobile && (
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                        className="menu-button"
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                <div style={{ cursor: "pointer" }} onClick={() => navigate("/catalogue")}>
                    <img src={Logo} alt="Logo" className="header-logo" />
                </div>
                <div>
                    {isMobile && (
                        <IconButton color="inherit" onClick={handleNavigate}>
                            <motion.div variants={variants} whileHover="hover" whileTap="click">
                                <Badge badgeContent={totalCartItem} color="secondary">
                                    <ShoppingCartIcon />
                                </Badge>
                            </motion.div>
                        </IconButton>
                    )}
                </div>
            </div>
            {!isMobile && (
                <div className="header-right">
                    {location.pathname === "/catalogue" && (
                        <>
                            {/* <LocationOnIcon className="header-icon" />
                            <Typography variant="body2" className="header-text">
                                {currentStore && currentStore.address}
                            </Typography> */}
                            {!(isLocationAvailable == "true") && (
                                <IconButton
                                    color="inherit"
                                    onClick={() => setShowLocationPopup(true)}
                                >
                                    <EditLocationAltRoundedIcon />
                                    <Typography variant="body2" className="header-text">
                                        Change Store
                                    </Typography>
                                </IconButton>
                            )}
                        </>
                    )}
                    {/* <Button
                        variant="contained"
                        color="primary"
                        className="header-button"
                    >
                        Set Location
                    </Button> */}
                    {getTokenData && (
                        <IconButton
                            color="inherit"
                            onClick={handleMenuClick} // Open menu on click
                        >
                            <AccountCircleIcon className="header-icon" />
                            <Typography variant="body2" className="header-text">
                                {/* {getStoreData && getStoreData.name} */}
                                {/* <ListItemText primary={getTokenData && (getTokenData.data.customerdetails[0].name ? (getTokenData.data.customerdetails[0].name).toUpperCase() : (getUserData.username ? getUserData.username.toUpperCase() :getTokenData.data.customerdetails[0].phoneno))} /> */}
                                <ListItemText
                                    primary={
                                        getTokenData.data?.customerdetails?.name
                                            ? getTokenData.data.customerdetails.name
                                                  .toLowerCase()
                                                  .replace(/\b\w/g, (char) => char.toUpperCase())
                                            : getUserData.username
                                                  .toLowerCase()
                                                  .replace(/\b\w/g, (char) => char.toUpperCase())
                                    }
                                />
                            </Typography>
                        </IconButton>
                    )}
                    {getTableId > 0 && (
                        <>
                            <TableBarIcon />
                            <Typography
                                variant="body2"
                                className="header-text"
                                marginLeft={1}
                                marginRight={1}
                            >
                                {getTableName}
                            </Typography>
                        </>
                    )}

                    {getTokenData && (
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem
                                onClick={handleOrderHistory}
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <HistoryRoundedIcon className="menu-icon" />
                                <span className="menu-text">Order History</span>
                            </MenuItem>
                            <MenuItem
                                onClick={handleLogout}
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <LogoutRoundedIcon className="menu-icon" />
                                <span className="menu-text">Logout</span>
                            </MenuItem>
                        </Menu>
                    )}
                    <Box className="header-price-box">
                        <Typography variant="body2" className="header-price-text">
                            Grand Total: {getStoreData?.currencysymbol}
                            {getStoreData?.totalwoutroundoff == 0 && getStoreData?.pgid != 3
                                ? Math.round(totalToPay)
                                : totalToPay}
                        </Typography>
                    </Box>
                    <IconButton color="inherit" onClick={handleNavigate}>
                        <motion.div variants={variants} whileHover="hover" whileTap="click">
                            <Badge badgeContent={totalCartItem} color="secondary">
                                <ShoppingCartIcon />
                            </Badge>
                        </motion.div>
                    </IconButton>
                </div>
            )}
            {isMobile && (
                <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                    {menuItems}
                </Drawer>
            )}
        </header>
    );
};

export default Header;
