import axios from 'axios';
import { clearLocalStorage } from './clearlocalstorage';

const axiosApiFunction = (method, endpoint, paramsobj, token, body = null) => {
  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: `${process.env.REACT_APP_BACKEND_BASEURL}${endpoint}`,
      params: paramsobj,
      headers: {
        'Content-Type': 'application/json', // Adjust as needed
        'Accept': 'application/json',
        ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
      },
      data: body, // Include body in the request
    })
      .then(response => {
        resolve(response); // Resolve with the response data
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          // Redirect to login page if unauthorized
          // clearLocalStorage();
          localStorage.removeItem("userData");
          localStorage.removeItem("tokenData");

          window.location.href = '/';
        } else {
          reject(error); // Reject with other errors
        }
      });
  });
};

export default axiosApiFunction;
