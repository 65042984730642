import React, { useContext, useEffect, useState } from "react";
import { cashfree } from "../utils/cashfree";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import Spinner from "../component/Spinner/Spinner";
import ErrorContext from "../context/ErrorContext";
import { addCart, applyOrderLevelCharge, clearCart, updateItemLevelCharge } from "../redux/slice/CartSlice";
import "./PaymentPage.css";
import { Box, Container, Grid, Typography, Paper, Alert, Snackbar } from "@mui/material";
import axiosApiFunction from "../utils/axiosapifunc";
import axios from "axios";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import Header from "../component/Header/Header";
import Footer from "../component/Footer/Footer";
import Spo from "../assets/spo.png";
import Cash from "../assets/cash-payment 1.png";
import OnlinePayment from "../assets/payment-method 1.png";
import { loadStripe } from "@stripe/stripe-js";
import { pushStoreData } from "../redux/slice/StoreSlice";
import BackIcon from "../assets/left.png";

const PaymentPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [message, setMessage] = useState("");
    const [open, setOpen] = React.useState(false);
    const [storeDetail, setStoreDetail] = useState([]);
    const [totalWoutRoundoff, setTotalWoutRoundoff] = useState(0);

    const { apiError, setApiError, clearApiError } = useContext(ErrorContext);
    const [searchParams] = useSearchParams();
    const getCart = useSelector((state) => state.cart?.cartData);
    const getTotalTax = useSelector((state) => state.cart?.totalTax);
    const getTotalAmount = useSelector((state) => state.cart?.totalAmount);
    const getTotalDiscount = useSelector((state) => state.cart?.totalDiscount);
    const getTotalAmountWithTax = useSelector((state) => state.cart?.totalAmountWithTax);
    const getStore = useSelector((state) => state.store?.storeData);
    const orderLevelCharge = useSelector((state) => state.cart?.orderLevelCharge);
    const isDeliveryOrder = useSelector((state) => state.cart?.isDeliveryOrder);
    const totalItemLevelCharge = useSelector((state) => state.cart?.totalItemLevelCharge);
    
    const getTokenData = JSON.parse(localStorage.getItem("tokenData"));
    const getUserData = JSON.parse(localStorage.getItem("userData"));
    const locationData = JSON.parse(localStorage.getItem('locationData'));
    const companyId = JSON.parse(localStorage.getItem('companyId'));
    const getTableId = JSON.parse(localStorage.getItem('tblid')) || 0;
    const getTableName = JSON.parse(localStorage.getItem('tblname')) || "";
    const userInstructions = localStorage.getItem('userInstructions') || "";
    const orderTime = localStorage.getItem("order-time") || "";
    // const userData = JSON.parse(localStorage.getItem('userData'));
    const [currentPaymentGateway, setCurrentPaymentGateway] = useState("");
    // const currentPaymentGateway = "razorpay";
    const [payLaterEnabled, setPayLaterEnabled] = useState();

    let totalToPay = parseFloat(
        (
            Number(getTotalAmountWithTax) +
            (getTableId == 0 ? Number(totalItemLevelCharge) : 0) +
            (getUserData.isDeliveryType == 1 && getTableId == 0 ? Number(orderLevelCharge) : 0)
        ).toFixed(2)
    );

    let totalGST = 0;

    useEffect(() => {
        const companyId = JSON.parse(localStorage.getItem('companyId'));

        const fetchData = async () => {
            clearApiError();
            setIsLoading(true);

            const cartdata = JSON.parse(localStorage.getItem("cartData"));

            if (!cartdata || cartdata.length === 0) {
                navigate("/catalogue");
                setIsLoading(false);
                return;
            }

            dispatch(addCart(cartdata));

            async function getStoreDetails() {
                try {
                    const storeDetails = await axiosApiFunction(
                        "get",
                        "/api/v1/getstoredetails",
                        {
                            companyid: companyId,
                            locationid: locationData.locationid,
                        },
                        getTokenData.token
                    );

                    // Dispatching the store details to the redux store
                    dispatch(pushStoreData(storeDetails.data[0]));
                    setPayLaterEnabled(storeDetails.data[0]?.enablepaylater);
                    setStoreDetail(storeDetails.data);
                    setTotalWoutRoundoff(storeDetails.data[0].totalwoutroundoff);

                    let paymentGateway = storeDetails.data[0].pgid ? storeDetails.data[0].pgid : 0;

                    if(paymentGateway == 1) {
                        setCurrentPaymentGateway("cashfree");
                    } else if(paymentGateway == 2) {
                        setCurrentPaymentGateway("razorpay");
                    } else if(paymentGateway == 3) {
                        setCurrentPaymentGateway("stripe");
                    }
                } catch (error) {
                    // Set API error if the API call fails
                    setApiError(error.message);
                } finally {
                    setIsLoading(false); // Ensure loading state is updated in both success and failure
                }
            }

            await getStoreDetails();
        };

        fetchData();
    }, []);

    useEffect(() => {
        getCart.forEach((item) => {
            if(item?.isOrderLevelChargedProduct == true && isDeliveryOrder){
                dispatch(applyOrderLevelCharge(Number(getStore?.charges?.find(charge => charge.applicableon == 2).chargevalue)))
            }
        })
    }, [getStore])

    useEffect(() => {
        if (getCart && getCart.length > 0 && storeDetail.length > 0) {
            checkOrderStatus(storeDetail, currentPaymentGateway);
        }
    }, [getCart, storeDetail]);

    async function checkOrderStatus(storeDetail, currentPaymentGateway) {
        if (currentPaymentGateway === "cashfree") {
            const orderid = searchParams.get("order_id");
            setIsLoading(true);

            if (orderid) {
                let orderStatusResponse = await axiosApiFunction(
                    "get",
                    "/api/v1/checkpaymentstatus",
                    {
                        orderid: orderid,
                    },
                    getTokenData.token
                );


                if (orderStatusResponse.data.data.order_status === "PAID") {
                    // await saveOrder(orderid, "online", "", "success", storeDetail);
                    dispatch(applyOrderLevelCharge(0));
                    dispatch(updateItemLevelCharge(0));

                    navigate("/payment-success", {
                        state: {
                            uniqueKey: orderid,
                        },
                    });
                    
                } else {
                    navigate("/payment-failed");
                }

                dispatch(clearCart());
            }
            setIsLoading(false);
        } else if (currentPaymentGateway === "stripe") {
            const sessionId = searchParams.get("session_id");
            const orderId = searchParams.get("order_id");
            setIsLoading(true);

            if (sessionId) {
                
                try {
                    let stripeResponse = await axiosApiFunction(
                        "get",
                        "/api/v1/checkpaymentstatus",
                        {
                            orderid: orderId,
                            session_id: sessionId,
                            locationId: storeDetail[0].locationid
                        },
                        getTokenData.token
                    );
                    if (stripeResponse.data.success == 1 && stripeResponse.data.msg == "succeeded") {
                        // await saveOrder(orderId, "online", "", "success", storeDetail);
                        dispatch(applyOrderLevelCharge(0));
                        dispatch(updateItemLevelCharge(0));
    
                        navigate("/payment-success", {
                            state: {
                                uniqueKey: orderId,
                            },
                        });
                    } else {
                        navigate("/payment-failed");
                    }
                    dispatch(clearCart());
                } catch (error) {
                    navigate("/payment-failed");
                }
                
            }
            setIsLoading(false);
        }
    }

    for (const key in getTotalTax) {
        if (key === "SGST" || key === "CGST") {
            totalGST = totalGST + getTotalTax[key];
        }
    }

    function generateUniqueKey (locationId = null, companyId = null) {
        const dateTime = Math.floor(new Date().getTime() / 1000); // Get the current timestamp in milliseconds
        const randNumber = Math.floor(1000 + Math.random() * 9000); // Generate a random number between 1000 and 9999
        const result = `${dateTime}${randNumber}${locationId}${companyId}`;

        return result;
    }

    function getCurrentUnixTimestamp () {    
        return Math.floor(Date.now() / 1000);
    }

    const saveOrder = async (paymentType, transactionId, storeDetail) => {
        setIsLoading(true);
        let isChargeApplied = (getUserData.isDeliveryType == 1 && getTableId == 0) ? true: false;
        let orderTotalAmt =
            Number(getTotalAmountWithTax) +
            (getTableId == 0 ? Number(totalItemLevelCharge) : 0) +
            (isChargeApplied ? Number(orderLevelCharge) : 0);

        let customerData = await getTokenData.data;
        // let itemsArrayToSave = await getCart.map((item) => {
        //     // let itemOptions = item.optionsToAdd.map((option) => ({
        //     //     // id: option.id,
        //     //     merchant_id: option.refId.toString(),
        //     //     price: option.price,
        //     //     title: option.title,
        //     //     translations: null,
        //     //     unit_weight: option.weight,
        //     // }));

        //     let modifiers =
        //         item.optionsToAdd.length > 1
        //             ? item.optionsToAdd.slice(1).map((modifier) => ({
        //                 modifiercategory: modifier.optGroupRefIds[0][0],
        //                 modifiername: modifier.title,
        //                 modifierprice: modifier.price,
        //                 modifierqty: item.quantity,
        //                 modifierid: modifier.refId,
        //                 productmodifierid: "",
        //             }))
        //             : [];

        //     let productDiscount = parseFloat(
        //         (Number(item.discount) / 100) *
        //         (item.optionsToAdd.length > 0 ? item.optionsToAdd[0].price : item.price) *
        //         item.quantity
        //     ).toFixed(3);

        //     let calculateTotalTaxablePrice =
        //         item.optionsToAdd.length > 0
        //             ? parseFloat(
        //                 (
        //                     (item.totalPrice -
        //                         item.optionsToAdd
        //                             .slice(1)
        //                             .reduce((acc, mod) => acc + mod.price, 0)) *
        //                     item.quantity *
        //                     (1 - Number(item.discount) / 100) +
        //                     item.optionsToAdd.slice(1).reduce((acc, mod) => acc + mod.price, 0) *
        //                     item.quantity
        //                 ).toFixed(2)
        //             )
        //             : parseFloat(
        //                 (
        //                     item.totalPrice *
        //                     item.quantity *
        //                     (1 - Number(item.discount) / 100)
        //                 ).toFixed(2)
        //             );

        //     let itemTotal = item.totalPrice * item.quantity;

        //     return {
        //         productid: item.refId, // *** need to be changed */
        //         productcategoryid: item.categoryRefIds[0],
        //         modifiers: modifiers,
        //         taxprofileid: "",
        //         productportionid: item.optionsToAdd.length > 0 ? item.optionsToAdd[0].refId : "",
        //         productname: item.title,
        //         price:
        //             item.optionsToAdd.length > 0
        //                 ? parseFloat(item.optionsToAdd[0].price)
        //                 : parseFloat(item.price),
        //         quantity: item.quantity.toString(),
        //         taxableprice: calculateTotalTaxablePrice,
        //         totaltaxprice: calculateTotalTaxablePrice + item.itemTotalTax,
        //         total: itemTotal,
        //         charges: "",
        //         discountpercentage: item.discount,
        //         discount: productDiscount,
        //         taxes: item.itemLevelTax.map((tax) => ({
        //             rate: tax.structure.value.toString(),
        //             value: parseFloat((tax.structure.value * item.price) / 100),
        //             taxid: tax.title == "SGST" ? "1" : tax.title == "CGST" ? "2" : "",
        //         })),
        //         portionid: null,
        //         portionname: item.optionsToAdd.length > 0 ? item.optionsToAdd[0].title : null,
        //         offerid: item.offerId.toString(),
        //         promocode: "",
        //         offertype: "",
        //         discountedqty: 0,
        //         discounttype: 1,
        //         discounonproduct: productDiscount,
        //         totalwithoutdiscountortax: itemTotal,
        //         pricewithouttaxandmodifier: itemTotal,
        //         discountapplied: item.discount > 0 ? 1 : 0,
        //     };
        // });

        // const data = {
        //     companyid: companyId,
        //     locationid: locationData.locationid,
        //     datekey: moment().format("YYYYMMDD"),
        //     payload: {
        //         customer: {
        //             customerid: customerData?.id,
        //             phone: customerData?.customerdetails
        //                 ? customerData?.customerdetails.phoneno
        //                 : getUserData.usermobile, //pass phone number as a string e.g("9328893561")
        //             address: {
        //                 city: null,
        //                 landmark: null,
        //                 pin: "",
        //                 longitude: "",
        //                 latitude: "",
        //                 line_1: "",
        //                 line_2: "",
        //                 sub_locality: getUserData.isDeliveryType === 1 ? getUserData.completeAddress : "",
        //             },
        //             uniquekey: generateUniqueKey(5, 5), // random generated unique key
        //             email: customerData?.customerdetails ? customerData.customerdetails.email : "",
        //             name: customerData?.customerdetails
        //                 ? customerData?.customerdetails.name
        //                 : getUserData.username.toUpperCase(),
        //         },
        //         order: {
        //             products: itemsArrayToSave,
        //             details: {
        //                 coupon: "",
        //                 uniquekey: uniqueKey,
        //                 totalcharge: null,
        //                 order_level_total_charges: null,
        //                 payable_amount: getTotalAmountWithTax,
        //                 total_taxes: totalGST,
        //                 order_total: getTotalAmountWithTax,
        //                 order_state: 1, //1 = Placed
        //                 channel: 1,
        //                 item_level_total_charges: "",
        //                 item_taxes: totalGST,
        //                 discount: getTotalDiscount,
        //                 instructions: "",
        //                 created: moment().format("YYYY-MM-DD H:m:s"),
        //                 taxes: "",
        //                 tableid: getTableId,
        //                 tablename: getTableName,
        //                 order_level_total_taxes: "",
        //                 order_type: getTableId > 0 ? "1" : (getUserData.isDeliveryType === 1 ? "3" : "2"), // 3 - Home delivery, 2 - Take away
        //                 orderleveltaxrate: 0,
        //                 order_subtotal: getTotalAmount + getTotalDiscount, // *** check cart calculation of sub total
        //             },
        //             store: {
        //                 name: storeDetail[0].name,
        //                 longitude: storeDetail[0].longitude,
        //                 storeid: "1",
        //                 address: storeDetail[0].address,
        //                 latitude: storeDetail[0].latitude,
        //                 contact_phone: storeDetail[0].contact_phone,
        //                 currencycode: storeDetail[0].currencycode,
        //                 currencysymbol: storeDetail[0].currencysymbol
        //             },
        //             payment: [
        //                 {
        //                     amount: getTotalAmountWithTax.toString(),
        //                     payment_type: paymentType === "online" ? currentPaymentGateway : "COD",
        //                     srvr_trx_id: paymentType === "online" ? transactionId.toString() : "",
        //                     trascation_status: transactionStatus,
        //                     transaction_time:
        //                         paymentType === "online" ? moment().format("YYYY-MM-DD H:m:s") : "",
        //                 },
        //             ],
        //         },
        //     },
        // };

        let newItemsArray = await getCart.map((item) => {
            let itemOptions = item.optionsToAdd
                .filter((option) => Object.keys(option).length > 0)
                .map((option) => ({
                    // id: option.id,
                    merchant_id: option.refId.toString(),
                    price: option.price,
                    title: option.title,
                    group: {
                        merchant_id: option.groupRefID,
                        title: option.groupTitle,
                    },
                    unit_weight: option.weight,
                }));

            let productDiscount = parseFloat(
                ((Number(item.discount) / 100) *
                    (item.price > 0
                        ? item.price
                        : item.optionsToAdd.length > 0 &&
                          Object.keys(item.optionsToAdd[0]).length > 0
                        ? item.optionsToAdd[0].price
                        : 0) *
                    item.quantity
                ).toFixed(3));

            let calculateTotalTaxablePrice =
            item.optionsToAdd.length > 0
                ? parseFloat(
                    (
                        (item.totalPrice -
                            item.optionsToAdd
                                .slice(1)
                                .reduce((acc, mod) => acc + mod.price, 0)) *
                        item.quantity *
                        (1 - Number(item.discount) / 100) +
                        item.optionsToAdd.slice(1).reduce((acc, mod) => acc + mod.price, 0) *
                        item.quantity
                    ).toFixed(2)
                )
                : parseFloat(
                    (
                        item.totalPrice *
                        item.quantity *
                        (1 - Number(item.discount) / 100)
                    ).toFixed(2)
                );

            let itemTotal = item.totalPrice * item.quantity;

            let itemLvlChargesArray = [];
            if(getTableId == 0 && Number(item?.packagingCharge) > 0){
                itemLvlChargesArray.push({title:"packaging charge", value: item?.packagingCharge})
            }

            return {
                charges: itemLvlChargesArray,
                discount: productDiscount,
                food_type: item.foodType.toString(),
                image_landscape_url: null,
                image_url: item.imgUrl,
                merchant_id: item.refId.toString(),
                options_to_add: itemOptions,
                options_to_remove: [],
                price: item.price ? parseFloat(item.price) : parseFloat(item.optionsToAdd[0].price),
                offerid: item.offerId,
                quantity: item.quantity,
                taxes: item.itemLevelTax.map((tax) => ({
                    title: tax.title,
                    rate: tax.structure.value,
                    value: parseFloat((item.itemTotalTax / 2).toFixed(3)),
                })),
                title: item.title,
                total: itemTotal,
                total_with_tax: calculateTotalTaxablePrice + item.itemTotalTax,
                translations: null,
                unit_weight: item.weight,
                instructions: item.instructions,
            };
        });

        let finalOrderTotalAmt = (totalWoutRoundoff == 0 && currentPaymentGateway != 'stripe') ? Math.round(orderTotalAmt): orderTotalAmt;

        let chargesArray = [];
        if (getTableId == 0 && Number(totalItemLevelCharge) > 0) {
            chargesArray.push({ title: "packaging charge", value: totalItemLevelCharge });
        }

        if (isChargeApplied && Number(orderLevelCharge) > 0) {
            chargesArray.push({ title: "delivery charge", value: orderLevelCharge });
        }

        const newData = {
            companyid: String(companyId),
            locationid: String(locationData.locationid),
            datekey: moment().format("YYYYMMDD"),
            payload: {
                customer: {
                    address: {
                        city: "",
                        is_guest_mode: false,
                        line_1: "",
                        line_2: "",
                        landmark: "",
                        latitude: 0,
                        longitude: 0,
                        sub_locality: getUserData.isDeliveryType === 1 ? getUserData.completeAddress : "",
                        pin: "",
                        tag: "",
                    },
                    email: customerData?.customerdetails ? customerData.customerdetails.email : "",
                    name: customerData?.customerdetails.name
                        ? customerData?.customerdetails.name
                        : getUserData.username.toUpperCase(),
                    phone: customerData?.customerdetails.phoneno
                        ? customerData?.customerdetails.phoneno
                        : getUserData.usermobile,
                    uniquekey: generateUniqueKey(5, 5),
                },
                order: {
                    items: newItemsArray,
                    details: {
                        biz_id: null,
                        channel: "bopis",
                        ownership:"accrete",
                        charges: chargesArray,
                        coupon: "",
                        created: getCurrentUnixTimestamp(),
                        delivery_datetime: "",
                        order_deliver_date_time: orderTime ? orderTime : "ASAP",
                        discount: getTotalDiscount,
                        total_external_discount: 0,
                        ext_platforms: [],
                        id: "",
                        instructions: userInstructions,
                        item_level_total_charges: (getTableId == 0 ? totalItemLevelCharge : 0),
                        item_level_total_taxes: 0,
                        item_taxes: 0,
                        merchant_ref_id: "",
                        order_level_total_charges: isChargeApplied ? orderLevelCharge: 0,
                        order_level_total_taxes: totalGST,
                        order_state: "Placed",
                        order_subtotal: parseFloat((getTotalAmount + getTotalDiscount).toFixed(2)),
                        order_total: finalOrderTotalAmt,
                        payable_amount: finalOrderTotalAmt,
                        order_type:
                            getTableId > 0
                                ? "dinein"
                                : getUserData.isDeliveryType == 1
                                ? "delivery"
                                : "pickup",
                        state: "Placed",
                        taxes: [],
                        tableid: getTableId,
                        tablename: null,
                        total_charges: (getTableId == 0 ? totalItemLevelCharge : 0) +  (isChargeApplied ? orderLevelCharge: 0),
                        total_taxes: totalGST,
                    },
                    next_state: "Acknowledged",
                    next_states: ["Acknowledged", "Food Ready", "Dispatched", "Completed", "Cancelled"],
                    payment: [
                        {
                            amount: finalOrderTotalAmt,
                            option: paymentType === "online" ? currentPaymentGateway : "cash",
                            payment_type: paymentType === "online" ? currentPaymentGateway : "cash",
                            srvr_trx_id: paymentType === "online" ? transactionId.toString() : "",
                        },
                    ],
                    store: {
                        address: storeDetail[0].address,
                        latitude: storeDetail[0].latitude,
                        longitude: storeDetail[0].longitude,
                        merchant_ref_id: storeDetail[0].ref_id,
                        name: storeDetail[0].name,
                        contact_phone: storeDetail[0].contact_phone,
                        // currencycode: storeDetail[0].currencycode,
                        currencysymbol: storeDetail[0].currencysymbol,
                    },
                },
            }
        };
        
        try {
            const response = await axiosApiFunction(
                "post",
                "/api/v1/saveorder",
                {},
                getTokenData.token,
                newData
            );

            let resultResp1 = response.data;
            localStorage.removeItem("userInstructions");
            localStorage.removeItem("order-time");

            if (resultResp1.success === 1) {
                // setOpen(true);
                // setMessage(resultResp1.message);
                // setSeverity("success");
                // dispatch(applyOrderLevelCharge(0));

                // dispatch(updateItemLevelCharge(0));
                let userData =  JSON.parse(localStorage.getItem('userData'));
                userData.isDeliveryType = 1;
                localStorage.setItem('userData', JSON.stringify(userData));
                
                return resultResp1;
            } else {
                throw new Error(resultResp1.message);
            }
        } catch (error) {
            // setApiError(error.message);
            setOpen(true);
            setMessage(error.message);
            setSeverity("error");
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const handlePayLaterClick = async () => {
        // const uniqueKey = generateUniqueKey(5, 5);
        let uniqueKey;

        const response = await saveOrder("cash", "", storeDetail);

        if(response) {
            uniqueKey = Number(response.orderid);
            dispatch(applyOrderLevelCharge(0));
            dispatch(updateItemLevelCharge(0));

            navigate("/payment-success", {
                state: {
                    uniqueKey,
                },
            });
        } else {
            uniqueKey = 0;
            navigate("/payment-failed");
        }

        dispatch(clearCart());
    };

    const handlePaymentClick = async () => {
        let customerData = await getTokenData.data;
        let uniqueKey = 0;

        const response = await saveOrder("online", "", storeDetail);

        if(response) {
            uniqueKey = Number(response.orderid);


            if (currentPaymentGateway === "razorpay") {
                try {
                    setIsLoading(true);

                    const orderIdResponse = await axiosApiFunction(
                        "post",
                        "/api/v1/createorder",
                        {},
                        getTokenData.token,
                        {
                            amount:  totalWoutRoundoff == 0 ? Math.round(totalToPay): totalToPay,
                            currency: "INR",
                            current_payment_gateway: currentPaymentGateway,
                            locationid: locationData.locationid
                        }
                    );
        
                    const orderIdData = orderIdResponse.data.data;
        
                    // const uniqueKey = generateUniqueKey(5, 5);
                    const options = {
                        key: storeDetail[0].paymentappid,
                        amount: orderIdData.amount,
                        currency: "INR",
                        name: storeDetail[0].name,
                        description: uniqueKey,
                        order_id: orderIdData.id,
                        handler: async (response) => {
                            try {
                                const data = {
                                    razorpay_order_id: response.razorpay_order_id,
                                    razorpay_payment_id: response.razorpay_payment_id,
                                    razorpay_signature: response.razorpay_signature,
                                };
        
                                navigate("/payment-success", {
                                    state: {
                                        uniqueKey,
                                    },
                                });
        
                                dispatch(clearCart());
                                dispatch(applyOrderLevelCharge(0));
                                dispatch(updateItemLevelCharge(0));
                            } catch (error) {
                                console.log("payment error response", error);
                            }
                        },
                        prefill: {
                            name: customerData?.customerdetails
                                ? customerData?.customerdetails.name
                                : getUserData.username.toUpperCase(),
                            email: customerData?.customerdetails ? customerData.customerdetails.email : "",
                            contact: customerData?.customerdetails
                                ? customerData?.customerdetails.phoneno
                                : getUserData.usermobile,
                        },
                        notes: {
                            address: customerData?.customerdetails
                                ? customerData?.customerdetails.address
                                : "",
                        },
                        theme: {
                            color: "#08415c",
                        },
                    };
        
                    let razorPay = new window.Razorpay(options);
                    razorPay.open();
        
                    let paymentFailed = false;
        
                    razorPay.on("payment.failed", async (response) => {
                        if (!paymentFailed) {
                            paymentFailed = true;
    
                            // await saveOrder(uniqueKey, "online", "", "failed", storeDetail);
                            // navigate("/payment-failed");
                            // dispatch(clearCart());
                        }
                    });
                } catch(error) {
                    setApiError(error.message);
                    navigate('/server-not-reachable');
                } finally {
                    setIsLoading(false);
                }
                
            } else if (currentPaymentGateway === "cashfree") {
                // const uniqueKey = generateUniqueKey(5, 5);
                
                try {
                    setIsLoading(true);

                    const sessionIdResponse = await axiosApiFunction(
                        "post",
                        "/api/v1/createorder",
                        {},
                        getTokenData.token,
                        {
                            customer_details: {
                                customer_id: (customerData?.customerdetails
                                    ? customerData?.customerdetails?.customerid
                                    : 0
                                ).toString(),
                                customer_phone: customerData?.customerdetails
                                    ? customerData?.customerdetails.phoneno
                                    : getUserData.usermobile,
                                customer_name: customerData?.customerdetails
                                    ? customerData?.customerdetails.name
                                    : getUserData.username.toUpperCase(),
                            },
                            order_id: uniqueKey.toString(),
                            order_amount: totalWoutRoundoff == 0 ? Math.round(totalToPay): totalToPay,
                            order_currency: "INR",
                            current_payment_gateway: currentPaymentGateway,
                            locationid: locationData.locationid
                        }
                    );
        
                    const sessionIdData = sessionIdResponse.data.data;
        
                    let checkoutOptions = {
                        paymentSessionId: sessionIdData.payment_session_id,
                        returnUrl: `${window.location.origin}/payment?order_id={order_id}`,
                    };
        
                    cashfree
                        .checkout(checkoutOptions)
                        .then(function (result) {
                            if (result.error) {
                                console.log("error while paying money", result);
                            }
                            if (result.redirect) {
                                console.log("redirection", result);
                            }
                        })
                        .catch((error) => {
                            console.error("Error during checkout:", error);
                        });
                } catch (error) {
                    setApiError(error.message)
                    navigate('/server-not-reachable');
                } finally {
                    setIsLoading(false);
                }
                
            } else if (currentPaymentGateway === "stripe") {
                // const uniqueKey = generateUniqueKey(5, 5);

                const stripe = await loadStripe(storeDetail[0].paymentappid);
    
                let lineItems = getCart.map((item) => ({
                    description: item.description,
                    imgUrl: item.imgUrl,
                    totalPrice: parseFloat((item.totalPrice + (item.itemTotalTax/item.quantity)).toFixed(2)),
                    quantity: item.quantity,
                    title: item.title
                }));
    
                try {
                    const stripeResponse = await axiosApiFunction(
                        "post",
                        "/api/v1/createorder",
                        {},
                        getTokenData.token,
                        {
                            line_items: lineItems,
                            // amount: Math.round(getTotalAmountWithTax),
                            delivery_charge: parseFloat(orderLevelCharge.toFixed(2)),
                            packaging_charge: parseFloat(totalItemLevelCharge.toFixed(2)),
                            currency: 'AUD',
                            payment_method_id: 'pm_card_visa',
                            order_id: uniqueKey,
                            success_url: `${window.location.origin}/payment`,
                            cancel_url: `${window.location.origin}/payment`,
                            current_payment_gateway: currentPaymentGateway,
                            locationid: storeDetail[0].locationid
                        }
                    );
                    let stripeRes = stripeResponse.data;
    
                    await stripe.redirectToCheckout({
                        sessionId: stripeRes.msg
                    });
                } catch (error) {
                    console.error('Error with stripe request:', error);
                    navigate('/server-not-reachable');
                } finally {
                    setIsLoading(false);
                }
            }
        } else {
            navigate("/payment-failed");
        }
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const handleBack = () => {
        navigate('/cart');
    }

    return (
        <div>
            <Header />
            <Spinner enabled={isLoading} />
            <Container className="payment-root">
                <Snackbar
                    open={open}
                    autoHideDuration={5000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    <Alert
                        onClose={handleClose}
                        severity={severity}
                        sx={{ width: "100%" }}
                        style={{ marginTop: "50px", textAlign: "start" }}
                    >
                        {message}
                    </Alert>
                </Snackbar>
                <Box className="securePayment">
                    {/* <Typography variant="h6" className="secureIcon">
                        ✔
                    </Typography> */}
                    <img src={Spo} alt="SPO" />
                    <Typography variant="h6">Secure Payment Options</Typography>
                </Box>
                {apiError ? (
                    <></>
                ) : (
                    <>
                        <Paper
                            elevation={2}
                            className="paymentSummary payment-customize rounded-paper-payment"
                        >
                            <div className="backpartpayment">
                                <div className="backiconsetpayment">
                                    <img src={BackIcon} alt="Back" onClick={handleBack} />
                                </div>
                            </div>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className="direction-payment-total">
                                    <Typography
                                        variant="h6"
                                        className="amount-title payment-titles"
                                    >
                                        To Pay
                                    </Typography>
                                    <Typography variant="h5" className="amount-setup">
                                        {storeDetail[0]?.currencysymbol}
                                        {totalWoutRoundoff == 0 && getStore?.pgid != 3
                                            ? Math.round(totalToPay)
                                            : totalToPay}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Grid
                            container
                            spacing={2}
                            justifyContent="center"
                            className="main-payment-option"
                        >
                            {payLaterEnabled ? (
                                <>
                                    <Grid item xs={5} className="payment-grid-item">
                                        <Paper
                                            className="paymentBox rounded-paper-payment"
                                            elevation={3}
                                            onClick={handlePayLaterClick}
                                        >
                                            <div className="img-setup-box">
                                                <img src={Cash} alt="Cash" />
                                            </div>
                                            <Typography variant="h6" className="payment-titles">
                                                Cash On Delivery
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                    {currentPaymentGateway.length > 0 && (
                                        <div className="divider" />
                                    )}
                                </>
                            ) : null}
                            {currentPaymentGateway && (
                                <Grid item xs={5} className="payment-grid-item">
                                    <Paper
                                        className="paymentBox rounded-paper-payment"
                                        elevation={3}
                                        onClick={handlePaymentClick}
                                    >
                                        {/* <CreditCardIcon className="icon" /> */}
                                        <div className="img-setup-box">
                                            <img src={OnlinePayment} alt="OnlinePayment" />
                                        </div>
                                        <Typography variant="h6" className="payment-titles">
                                            Online
                                        </Typography>
                                    </Paper>
                                </Grid>
                            )}
                        </Grid>
                    </>
                )}
            </Container>
            {/* <Footer /> */}
        </div>
    );
};

export default PaymentPage;
