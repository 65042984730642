import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../component/Footer/Footer";
import "./PrivacyPolicy.css";
import Logo from "../assets/company-logo.png";
import BackIcon from "../assets/left.png";

function PrivacyPolicy() {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate("/");
    };

    return (
        <div>
            <div className="privacy-policy-container">
                <div className="static-page-back">
                    <img src={BackIcon} alt="Back" onClick={handleBack} />
                </div>
                <div className="logo-container">
                    <img src={Logo} alt="Company Logo" className="company-logo" />
                </div>
                <h1 className="privacy-title">Privacy Policy</h1>
                <p>
                    This privacy policy ("Policy") describes how we collect, protect and use the
                    personally identifiable information ("Personal Information") you ("User", "you"
                    or "your") provided on the <strong>https://www.billberrypos.com/</strong>{" "}
                    website and any of its products or services (collectively, "Website" or
                    "Services") owned and operated by Billberry. It also describes the choices
                    available to you regarding our use of your personal information and how you can
                    access and update this information. This Policy does not apply to the practices
                    of companies that we do not own or control, or to individuals that we do not
                    employ or manage.
                </p>
                <h2 className="privacy-section-title">Collection of personal information</h2>
                <p>
                    We receive and store any information you knowingly provide to us when you create
                    an account, make a purchase, fill any online forms on the Website. When required
                    this information may include your email address, name, phone number, address,
                    credit card information, bank information, or other Personal Information. You
                    can choose not to provide us with certain information, but then you may not be
                    able to take advantage of some of the Website's features.
                </p>
                <h2 className="privacy-section-title">Collection of non-personal information</h2>
                <p>
                    When you visit the Website our servers automatically record information that
                    your browser sends. This data may include information such as your computer's IP
                    address, browser type and version, operating system type and version, language
                    preferences or the webpage you were visiting before you came to our Website,
                    pages of our Website that you visit, the time spent on those pages, information
                    you search for on our Website, access times and dates, and other statistics.
                </p>
                <h2 className="privacy-section-title">Managing personal information</h2>
                <p>
                    You are able to access, add to, update and delete certain Personal Information
                    about you. The information you can view, update, and delete may change as the
                    Website or Services change. When you update information, however, we may
                    maintain a copy of the unrevised information in our records. We will retain your
                    information for as long as your account is active or as needed to provide you
                    Services. Some information may remain in our private records after your deletion
                    of such information from your account. We will retain and use your information
                    as necessary to comply with our legal obligations, resolve disputes, and enforce
                    our agreements. We may use any aggregated data derived from or incorporating
                    your Personal Information after you update or delete it, but not in a manner
                    that would identify you personally.
                </p>
                <h2 className="privacy-section-title">Use of collected information</h2>
                <p>
                    Any of the information we collect from you may be used to personalize your
                    experience; improve our website; improve customer service and respond to queries
                    and emails of our customers; process transactions; send notification emails such
                    as password reminders, updates, etc; run and operate our Website and Services.
                    Non-personal information collected is used only to identify potential cases of
                    abuse and establish statistical information regarding Website traffic and usage.
                    This statistical information is not otherwise aggregated in such a way that it
                    would identify any particular user of the system.
                </p>
                <h2 className="privacy-section-title">Billing and payments</h2>
                <p>
                    We use third-party payment processors to assist us in processing your payment
                    information securely. Such third party processors' use of your Personal
                    Information is governed by their respective privacy policies which may or may
                    not contain privacy protections as protective as this Privacy Policy. We suggest
                    that you review their respective privacy policies.
                </p>
                <h2 className="privacy-section-title">Children</h2>
                <p>
                    We do not knowingly collect any personal information from children under the age
                    of 13. If you are under the age of 13, please do not submit any personal
                    information through our Website or Service. We encourage parents and legal
                    guardians to monitor their children's Internet usage and to help enforce this
                    Policy by instructing their children never to provide personal information
                    through our Website or Service without their permission. If you have reason to
                    believe that a child under the age of 13 has provided personal information to us
                    through our Website or Service, please contact us.
                </p>
                <h2 className="privacy-section-title">Newsletters</h2>
                <p>
                    We offer electronic newsletters to which you may voluntarily subscribe at any
                    time. You may choose to stop receiving our newsletter or marketing emails by
                    following the unsubscribe instructions included in these emails or by contacting
                    us. However, you will continue to receive essential transactional emails.
                </p>
                <h2 className="privacy-section-title">Cookies</h2>
                <p>
                    The Website uses "cookies" to help personalize your online experience. A cookie
                    is a text file that is placed on your hard disk by a web page server. Cookies
                    cannot be used to run programs or deliver viruses to your computer. Cookies are
                    uniquely assigned to you, and can only be read by a web server in the domain
                    that issued the cookie to you. We may use cookies to collect, store, and track
                    information for statistical purposes to operate our Website and Services. You
                    have the ability to accept or decline cookies. Most web browsers automatically
                    accept cookies, but you can usually modify your browser setting to decline
                    cookies if you prefer.
                </p>
                <h2 className="privacy-section-title">Information security</h2>
                <p>
                    We secure information you provide on computer servers in a controlled, secure
                    environment, protected from unauthorized access, use, or disclosure. We maintain
                    reasonable administrative, technical, and physical safeguards in an effort to
                    protect against unauthorized access, use, modification, and disclosure of
                    personal information in its control and custody. However, no data transmission
                    over the Internet or wireless network can be guaranteed. Therefore, while we
                    strive to protect your personal information, you acknowledge that (i) there are
                    security and privacy limitations of the Internet which are beyond our control;
                    (ii) the security, integrity, and privacy of any and all information and data
                    exchanged between you and our Website cannot be guaranteed; and (iii) any such
                    information and data may be viewed or tampered with in transit by a third party,
                    despite best efforts.
                </p>
                <h2 className="privacy-section-title">Data breach</h2>
                <p>
                    In the event we become aware that the security of the Website has been
                    compromised or users Personal Information has been disclosed to unrelated third
                    parties as a result of external activity, including, but not limited to,
                    security attacks or fraud, we reserve the right to take reasonably appropriate
                    measures, including, but not limited to, investigation and reporting, as well as
                    notification to and cooperation with law enforcement authorities. In the event
                    of a data breach, we will make reasonable efforts to notify affected individuals
                    if we believe that there is a reasonable risk of harm to the user as a result of
                    the breach or if notice is otherwise required by law. When we do we will send
                    you an email.
                </p>
                <h2 className="privacy-section-title">Changes and amendments</h2>
                <p>
                    We reserve the right to modify this privacy policy relating to the Website or
                    Services at any time, effective upon posting of an updated version of this
                    privacy policy on the Website. When we do we will revise the updated date at the
                    bottom of this page. Continued use of the Website after any such changes shall
                    constitute your consent to such changes.
                </p>
                <h2 className="privacy-section-title">Acceptance of this policy</h2>
                <p>
                    You acknowledge that you have read this Policy and agree to all its terms and
                    conditions. By using the Website or its Services you agree to be bound by this
                    Policy. If you do not agree to abide by the terms of this Policy, you are not
                    authorized to use or access the Website and its Services.
                </p>
                <h2 className="privacy-section-title">Contacting us</h2>
                <p>
                    If you have any questions about this Policy, please contact us at{" "}
                    <strong>support@accreteinfo.com</strong>
                </p>
                {/* <h3 className="privacy-subtitle">Why are cookies important?</h3>
                <p>
                    Cookies help you make your online experience more efficient and relevant to your
                    interests.
                </p>
                <h3 className="privacy-subtitle">What is a Cookie?</h3>
                <p>
                    A cookie is a small file, or files on your device with a browser to save
                    snippets of text for reference by the website you are visiting.
                </p>
                <p>Cookies are grouped into the following categories:</p>
                <ul>
                    <li>
                        <strong>Essential</strong> - These are required for the regular operation of
                        our websites.
                    </li>
                    <li>
                        <strong>Functional</strong> - These remember your preferences to enhance
                        your experience.
                    </li>
                    <li>
                        <strong>Analytics</strong> - These cookies are used for performance
                        measurement and understanding user behavior.
                    </li>
                    <li>
                        <strong>Advertising</strong> - These cookies enable us and our advertising
                        partners to serve relevant ads.
                    </li>
                </ul> */}
            </div>
            <Footer />
        </div>
    );
}

export default PrivacyPolicy;
