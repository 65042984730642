import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Header from "../component/Header/Header";
import Footer from "../component/Footer/Footer";
import Sidebar from "../component/Sidebar/Sidebar";
import Grid from "@mui/material/Grid";
import dummy from "../assets/DummyImage.png";
import bifurcateCategoriesAndItems, { categories } from "../utils/jsonconverter";
import { Alert, Button, Chip, IconButton, Paper, Snackbar, Typography, Select, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useDispatch, useSelector } from "react-redux";
import { pushStoreData } from "../redux/slice/StoreSlice";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import "./Home.css";
import { addCart, addItem, removeOffer, decreaseQtyFromCatalogue, clearCart, applyOrderLevelCharge } from "../redux/slice/CartSlice";
import axiosApiFunction from "../utils/axiosapifunc";
import Spinner from "../component/Spinner/Spinner";
import "../component/Grid/ProductGrid.css";
import OfferScroller from "../component/OffersAndRecommendation/OfferScroller";
import RecommendedItems from "../component/OffersAndRecommendation/RecommendedItems";
import DialogComp from "../component/ModalComp/DialogComp";
import AddToCartPopUp from "../component/PopUp/AddToCartPopUp";
import StoreLocationPopup from "../component/ModalComp/StoreLocationPopup";
import SearchModal from "../component/ModalComp/SearchModal";
import OfferContext from "../context/OfferContext";
import LocationContext from "../context/LocationContext";
import ErrorContext from "../context/ErrorContext";
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import ScrollBottomToTop from "../component/Button/ScrollBottomToTop";
import sbp from"../../src/assets/sort.png";

function HomePage() {
    const [offers, setOffers] = useState([]);
    const [recommendedItems, setRecommendedItems] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();
    const [category, setCategory] = useState([]);
    const [expandedDescriptions, setExpandedDescriptions] = useState({});
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [isLoading, setIsLoading] = useState(true);
    const [isLocationLoading, setIsLocationLoading] = useState(true);
    const [isOffersLoading, setIsOffersLoading] = useState(true);
    const [isMenuLoading, setIsMenuLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSearchDialog, setOpenSearchDialog] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [searchItems, setSearchItems] = useState([]);
    const [dialogItem, setDialogItem] = useState([]);
    const [sortOrder, setSortOrder] = useState('');
    const [stores, setStores] = useState([]);
    const [showLocationPopup, setShowLocationPopup] = useState(false);
    const locationCtx = useContext(LocationContext);
    const {apiError, setApiError, clearApiError}= useContext(ErrorContext);
    const totalDiscount = useSelector((state) => state.cart?.totalDiscount);
    const getStore = useSelector((state) => state.store?.storeData);
    const cartItems = useSelector((state)=>state.cart?.cartData);
    const offerCtx = useContext(OfferContext);
    const [currentLocationId, setCurrentLocationId] = useState(0);
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    const toggleReadMore = (itemId) => {
        setExpandedDescriptions(prevState => ({
            ...prevState,
            [itemId]: !prevState[itemId]
        }));
    };

    const dispatch = useDispatch();
    const categoryRefs = useRef({});

    const handleCategorySelect = (categoryId) => {
        setSelectedCategory(categoryId);
        if (categoryRefs.current[categoryId]) {
            categoryRefs.current[categoryId].scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };

    const handleLocationSelect = (location) => {
        localStorage.setItem('locationData', JSON.stringify(location));
        locationCtx.handleLocationChange(location);
        setCurrentLocationId(Number(location.locationid));
        dispatch(clearCart());
        setShowLocationPopup(false);  // Close the pop-up
    };

    const convertToPlainObject = (obj) => {
        if (obj instanceof Object) {
          return {
            refId: obj.refId,
            title: obj.title,
            options: obj.options.map(option => ({ ...option })),
            sortOrder: obj.sortOrder,
            item_ref_ids: [...obj.item_ref_ids]
          };
        }
        return obj;
      };
      
      const convertOptionToPlainObject = (option) => {
        if (option && Object.keys(option).length === 0 && option.constructor === Object) {
            return {};
        }

        if (option instanceof Object) {
            return {
                refId: option.refId,
                title: option.title,
                groupRefID: option.groupRefID,
                groupTitle: option.groupTitle,
                description: option.description,
                weight: option.weight,
                available: option.available,
                price: option.price,
                markupPrice: option.markupPrice,
                soldAtStore: option.soldAtStore,
                sortOrder: option.sortOrder,
                optGroupRefIds: [option?.optGroupRefIds],
                nestedOptGroups: [option?.nestedOptGroups],
                foodType: option.foodType
            };
        }
        return option;
    };

    const handleAddToCartClosePopup = () => {
        setShowPopup(false);
      };

    const addProductToCart = (item, firstVariationPrice) => {
        if (item.available == 1 || item.available == true) {
            if(item?.optionsToAdd?.length > 0 ? false : item.optionGroups.length > 0 ){
                setOpenDialog(true);
                setDialogItem(item);
            }else{
                setShowPopup(true);
                // setMessage('Product added to cart successfully!');
                // setSeverity('success');
                const itemData = {
                    available: item.available,
                    currentStock: Number(item.currentStock),
                    description: item.description,
                    foodType: item.foodType,
                    imgUrl: item.imgUrl,
                    markupPrice: item.markupPrice,
                    // optionGroups: item.optionGroups ? item.optionGroups.map(group => convertToPlainObject(group)) : [],
                    price: item.price,
                    recommended: item.recommended,
                    refId: Number(item.refId),
                    title: item.title,
                    categoryRefIds: item.categoryRefIds,
                    soldAtStore: item.soldAtStore,
                    sortOrder: item.sortOrder,
                    weight: item.weight,
                    quantity: item.quantity || 1,
                    totalPrice: Number((item.price !== 0) ? item.price : firstVariationPrice),
                    itemTotalTax: 0,
                    offerId: 0,
                    offerName: '',
                    instructions: '',
                    discount: 0,
                    productPortionId: 0,
                    taxProfileId: 0,
                    portionName: "",
                    optionsToAdd: item.optionsToAdd ? item.optionsToAdd.map(option => convertOptionToPlainObject(option)) : [],
                    chargesArray: getStore?.charges || []
                  };
                
                if(totalDiscount > 0) {
                    offerCtx.resetOffer();
    
                    const offerData = {
                        offername: "",
                        offerdescription: "",
                        isofferapplied: false
                    }
                    localStorage.setItem('offerData', JSON.stringify(offerData));
                    dispatch(removeOffer());
                    dispatch(addItem(itemData));
                } else {
                    dispatch(addItem(itemData));
                }

                setOpen(true);
            }
          } else {
            setMessage('Product is not available!');
            setSeverity('error');
          }
          item.optionsToAdd = [];
      };
      
      setTimeout(() => {
        setOpen(false);
      }, 2500);


    useEffect(() => {
        clearApiError();
        // setIsLoading(true);
        setIsLoading(true);
        setIsLocationLoading(true);
        setIsOffersLoading(true);
        setIsMenuLoading(true);

        const companyId = JSON.parse(localStorage.getItem('companyId'));

        const fetchStoreLocation = async () => {
            const locationData = JSON.parse(localStorage.getItem("locationData"));

            try {
                const storesDetails = await axiosApiFunction(
                    "get",
                    "/api/v1/getbopisstores",
                    { companyid: companyId }
                );

                setStores(storesDetails.data);
                if (locationData) {
                    locationCtx.handleLocationChange(locationData);
                    setCurrentLocationId(Number(locationData.locationid));
                } else {
                    localStorage.setItem("locationData", JSON.stringify(storesDetails.data[0]));
                    locationCtx.handleLocationChange(storesDetails.data[0]);
                    setCurrentLocationId(Number(storesDetails.data[0].locationid));
                }
            } catch (error) {
                setApiError(error.message);
                setIsLoading(false);
            } finally {
                setIsLocationLoading(false); // Complete location loading
            }
        };

        const fetchOfferData = async () => {
            const locationData = JSON.parse(localStorage.getItem("locationData"));

            try {
                const response = await axiosApiFunction(
                    "get",
                    "/api/v1/getoffers",
                    { companyid: companyId, locationid: Number(locationData.locationid)}
                );
                setOffers(response.data);
            } catch (error) {
                setApiError(error.message);
                setIsLoading(false);
            } finally {
                setIsOffersLoading(false); // Complete offers loading
            }
        }

        const fetchData = async () => {
            if (currentLocationId === 0) return;

            try {
                const menuData = await axiosApiFunction('get', '/api/v1/fetchmenu', { companyid: companyId, locationid: currentLocationId }
                );
        
                localStorage.setItem('menuData', JSON.stringify(menuData.data[0].menudata))
                let categoriesJson = menuData.data[0].menudata.categories;
                let itemsJson = menuData.data[0].menudata.items;
                let optionGroupsJson = menuData.data[0].menudata.option_groups;
                let optionsJson = menuData.data[0].menudata.options;
                let combinedItems = [];

                bifurcateCategoriesAndItems(categoriesJson, itemsJson, optionGroupsJson, optionsJson);
                setCategory(categories);

                if (categories.length > 0) {
                    setSelectedCategory(categories[0].refId);
                }

                let allItems = [];

                categories.forEach((category) => {
                    // Add all items from the main category
                    if (category.items && category.items.length > 0) {
                        allItems = [
                            ...allItems,
                            ...category.items.filter((item) => item.recommended === true),
                        ];
                    }

                    // Check subCategories for items
                    category.subCategories.forEach((subCategory) => {
                        if (subCategory.items && subCategory.items.length > 0) {
                            allItems = [
                                ...allItems,
                                ...subCategory.items.filter((item) => item.recommended === true),
                            ];
                        }
                    });
                });

                  setRecommendedItems(allItems);
                  localStorage.setItem('recommended', JSON.stringify(allItems));

                for (let i = 0; i < categories.length; i++) {
                    const currentItem = categories[i];

                    if (currentItem.items && currentItem.items.length > 0) {
                        combinedItems = [...combinedItems, ...currentItem.items];
                    }

                    if (currentItem.subCategories && currentItem.subCategories.length > 0) {
                        for (let j = 0; j < currentItem.subCategories.length; j++) {
                            const subCategory = currentItem.subCategories[j];
                            if (subCategory.items && subCategory.items.length > 0) {
                                combinedItems = [...combinedItems, ...subCategory.items];
                            }
                        }
                    }
                }

                setSearchItems(combinedItems);
            } catch (error) {
                setIsLoading(false);
                setApiError(error.message);
            }
    
            try {
                const storeDetails = await axiosApiFunction('get', '/api/v1/getstoredetails', { companyid: companyId, locationid: currentLocationId }
                );
                dispatch(pushStoreData(storeDetails.data[0]));
            } catch (error) {
                setApiError(error.message);
            } finally {
                setIsMenuLoading(false); // Complete menu loading
            }
        };
    
        const fetchAllData = async () => {
            
            try {
                await fetchStoreLocation();
                await fetchOfferData();
                await fetchData();
            } catch (error) {
                setApiError(error.message);
                setIsLoading(false);
            }
        };
    
        fetchAllData();

        const cartdata =  JSON.parse(localStorage.getItem('cartData'));
        if(cartdata) {
            dispatch(addCart(cartdata));
        }
    }, [dispatch, currentLocationId]);

    useEffect(() => {
        if (!isLocationLoading && !isOffersLoading && !isMenuLoading) {
            setIsLoading(false);
        }
    }, [isLocationLoading, isOffersLoading, isMenuLoading]);

    useEffect(() => {
        cartItems.forEach((item) => {
            if(item?.isOrderLevelChargedProduct == true){
                dispatch(applyOrderLevelCharge(Number(getStore?.charges?.find(charge => charge.applicableon == 2).chargevalue)))
            }
        })
    }, [getStore])
    

    const handleSortChange = (event) => {
        const order = event.target.value;
        setSortOrder(order);
        sortItems(order);
    };

    const sortItems = (order) => {
        const sortedCategories = category.map(cat => ({
            ...cat,
            items: [...cat.items].sort((a, b) => {
                if (order === 'asc') {
                    return a.price - b.price;
                }
                return b.price - a.price;
            }),
            subCategories: cat.subCategories.map(subCat => ({
                ...subCat,
                items: [...subCat.items].sort((a, b) => {
                    if (order === 'asc') {
                        return a.price - b.price;
                    }
                    return b.price - a.price;
                })
            }))
        }));
        setCategory(sortedCategories);
    };
    
    const formatTimeTo12Hour = (time24) => {
        const [hour, minute] = time24.split(':');
        const hourInt = parseInt(hour, 10);
        const period = hourInt >= 12 ? 'PM' : 'AM';
        const hour12 = hourInt % 12 || 12;
        return `${hour12}:${minute} ${period}`;
    };

    const isStoreOpen = (timings) => {
        const now = new Date();
        const currentDay = now.toLocaleString("en-US", { weekday: "long" }).toLowerCase();
        const currentTime = now.toTimeString().slice(0, 8);

        const getPriviousDay = (dayIndex) => {
            return dayIndex === 0 ? timings.length - 1 : dayIndex - 1;
        };

        const currentDayIndex = timings.findIndex((timing) => timing.day === currentDay);

        if (currentDayIndex === -1) {
            return false;
        }

        for (const timing of timings) {
            if (timing.day === currentDay) {
                for (const slot of timing.slots) {
                    const startTime = slot.start_time.slice(0, 8);
                    const endTime = slot.end_time.slice(0, 8);

                    if (startTime < endTime) {
                        if (currentTime >= startTime && currentTime < endTime) {
                            return true;
                        }
                    } else {
                        const previousDayIndex = getPriviousDay(currentDayIndex);
                        const previousDayTimings = timings[previousDayIndex];

                        for (const slot of previousDayTimings.slots) {
                            const previousDayEndTime = slot.end_time.slice(0, 8);

                            if (currentTime >= startTime) {
                                return true;
                            }

                            if (currentTime < previousDayEndTime) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    }
                }
            }
        }

        return false;
    };
    

    const getNextOpeningTime = (timings) => {
        const now = new Date();
        const currentDay = now.toLocaleString("en-US", { weekday: "long" }).toLowerCase(); // e.g., 'monday'
        const currentTime = now.toTimeString().slice(0, 8); // 'HH:MM'

        const getPriviousDay = (dayIndex) => {
            return dayIndex === 0 ? timings.length - 1 : dayIndex - 1;
        };

        const currentDayIndex = timings.findIndex((timing) => timing.day === currentDay);

        for (const timing of timings) {
            if (timing.day === currentDay) {
                for (const slot of timing.slots) {
                    const startTime = slot.start_time.slice(0, 8);
                    const endTime = slot.end_time.slice(0, 8);

                    if (startTime < endTime) {
                        if (currentTime >= startTime && currentTime < endTime) {
                            return `Open until ${formatTimeTo12Hour(endTime)}`;
                        }
                    } else {
                        const previousDayIndex = getPriviousDay(currentDayIndex);
                        const previousDayTimings = timings[previousDayIndex];

                        for (const slot of previousDayTimings.slots) {
                            const previousDayEndTime = slot.end_time.slice(0, 8);

                            if (currentTime >= startTime) {
                                return `Open until ${formatTimeTo12Hour(endTime)}`;
                            }

                            if (currentTime < previousDayEndTime) {
                                return `Open until ${formatTimeTo12Hour(previousDayEndTime)}`;
                            } else {
                            }
                        }
                    }
                }
            }
        }

        for (const timing of timings) {
            if (timing.day === currentDay) {
                for (const slot of timing.slots) {
                    const startTime = slot.start_time.slice(0, 8);

                    if (currentTime < startTime) {
                        return `Opens next on ${
                            currentDay.charAt(0).toUpperCase() + currentDay.slice(1)
                        } at ${formatTimeTo12Hour(startTime)}`;
                    }
                }
            }
        }

        for (let i = 1; i < timings.length; i++) {
            const nextDayTiming = timings[(currentDayIndex + i) % timings.length];
            if (nextDayTiming.slots.length > 0) {
                return `Opens next on ${
                    nextDayTiming.day.charAt(0).toUpperCase() + nextDayTiming.day.slice(1)
                } at ${formatTimeTo12Hour(nextDayTiming.slots[0].start_time)}`;
            }
        }
        return "No available opening times";
    };

    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(1),
          width: 'auto',
        },
      }));

      const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }));

      const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: 'inherit',
        width: '100%',
        '& .MuiInputBase-input': {
          padding: theme.spacing(1, 1, 1, 0),
          // vertical padding + font size from searchIcon
          paddingLeft: `calc(1em + ${theme.spacing(4)})`,
          transition: theme.transitions.create('width'),
          [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
              width: '20ch',
            },
          },
        },
      }));

      const isProductInCart = (item) => {
        return cartItems?.some(cartItem => cartItem.refId == item.refId);
      };
      
      const getProductQuantityInCart = (item) => {
          return cartItems
              ?.filter((cartItem) => cartItem.refId == item.refId)
              ?.reduce((total, cartItem) => total + cartItem.quantity, 0);
      };

      const handleSearch = () => {
        setOpenSearchDialog(true);
      }

      const handleLocationClick = () => {
          const googleMapsUrl = `https://www.google.com/maps/dir//${Number(
              getStore.latitude
          )},${Number(getStore.longitude)}`;
          window.open(googleMapsUrl, "_blank");
      };

    return (
        <div>
            <Header setShowLocationPopup={setShowLocationPopup} />
            {showPopup && <AddToCartPopUp onClose={handleAddToCartClosePopup} />}
            {/* <Snackbar
              open={open}
              autoHideDuration={5000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
            >
              <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {message}
              </Alert>
            </Snackbar> */}
            <Box sx={{ flexGrow: 300 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                        <div className="set-lf">
                            <Sidebar
                                onSelectCategory={handleCategorySelect}
                                categorydata={category}
                                selectedCategory={selectedCategory}
                                categoryRefs={categoryRefs}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={10} xl={10}>
                        <div className="product-grid-container">
                        <div className="header-container">
                            <div className="setup-address-store">
                            {Object.keys(getStore).length > 0 && <div className="store-info">
                                <div className="store-details">
                                    <div className="set-store-name-icon">
                                        <div className="icon-names">
                                        <StorefrontOutlinedIcon/>
                                        <div className="sna-setup">
                                        <h1 className="store-name">{getStore.name}</h1>
                                        <h2 className="store-address">{getStore.address}</h2>
                                        </div>
                                        </div>
                                        <div className="icon-names">
                                        <PhoneIphoneOutlinedIcon/>
                                        <span>{getStore.contact_phone}</span>
                                        </div>
                                    </div>
                                    {((getStore.active == "true" || getStore.active == true) && isStoreOpen(getStore.timings)) ? 
                                        (<div className="store-status open">OPEN
                                            <div className="next-opening-time">
                                                {getNextOpeningTime(getStore.timings)}
                                            </div>
                                        </div>) : 
                                        (<div className="store-status closed">CLOSED
                                            <div className="next-opening-time">
                                                {getNextOpeningTime(getStore.timings)}
                                            </div>
                                        </div>)
                                    }
                                </div>
                                <div 
                                    className="set-location-icon"
                                    onClick={handleLocationClick}
                                    style={{ cursor: "pointer" }}
                                >
                                    <NearMeOutlinedIcon className="search-on-map" />
                                </div>
                                {/* <div className="store-rating">
                                    <span className="rating">4.17</span>
                                    <span className="reviews">(2738+)</span>
                                </div> */}
                            </div>}
                            <div className="store-info">
                                <div className="search-bar-main-box" onClick={handleSearch}>
                                <SearchIcon />
                                {/* <Search onClick={handleSearch}>
                                    <SearchIconWrapper>
                                    <SearchIcon />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                    placeholder="Search…"
                                    inputProps={{ 'aria-label': 'search' }}
                                    />
                                </Search> */}
                                <p>Search Menu</p>
                                </div>
                                {/* <div className="vn-box">
                                    <span>
                                        <div className="veg-flag"><span></span></div> Pure-Veg
                                    </span>
                                </div> */}
                                <Select
                                    value={sortOrder}
                                    onChange={handleSortChange}
                                    variant="outlined"
                                    className="sort-button"
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled> <img className="sbpimgset" src={sbp} /></MenuItem>
                                    <MenuItem value="asc">Low to High</MenuItem>
                                    <MenuItem value="desc">High to Low</MenuItem>
                                </Select>
                            </div>
                            </div>
                                {/* <Button variant="outlined" className="sort-button" onClick={() => handleClick()}>
                                    Sort by Pricing
                                </Button> */}
                                
                            </div>
                            {/* { apiError && <span style={{color: 'red'}}>{apiError}<br /></span> } */}
                            { offers.length > 0 && <OfferScroller offers={offers} /> }
                            { recommendedItems.length > 0 && <RecommendedItems items={recommendedItems} addProductToCart={addProductToCart} /> }
                            <Spinner enabled={isLoading} />
                            {category && (
                                <Grid container spacing={2} className="home-category-grid">
                                    {category.map((category, index) => (
                                        <React.Fragment key={index}>
                                            {(category.items.length > 0 || (category.subCategories.length > 0 && category.subCategories.some(sub => sub.items.length > 0))) && (
                                                <h2
                                                    className="divider line one-line category-header title1"
                                                    ref={el => categoryRefs.current[category.refId] = el}
                                                >
                                                    <span>{category.title}</span>
                                                </h2>
                                            )}
                                            {category.items.length > 0 &&
                                                category.items.map((item, index) => (
                                                    <Grid item xs={12} sm={6} md={6} lg={3} key={index} className="item-cart-category">
                                                        <Paper className="product-card">
                                                            <div className="product-box-wc">
                                                                
                                                                <div className="pro-contentimg">
                                                                    <img
                                                                        src={item.imgUrl && item.imgUrl !== null && item.imgUrl !== "" ? item.imgUrl : dummy}
                                                                        onError={(e) => {
                                                                            e.target.src = dummy;
                                                                          }}
                                                                        alt="Product Image"
                                                                        className="product-image"
                                                                    />
                                                                </div>
                                                                <div className="pro-contentbox">
                                                                    <div className="d-flex align-items-center mb-2">
                                                                        <div className={item.foodType == 1 ? "veg-flag" : "non-vegflag"}>
                                                                            <span></span>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <Typography variant="h4" className="pro-title">
                                                                            {item.title}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                        <Typography variant="body1" className="product-price">
                                                                            {getStore?.currencysymbol}  {item.price ? item.price : item?.optionGroups.find((el) => el.item_ref_ids == item.refId)?.options[0].price}
                                                                        </Typography>
                                                                    </div>
                                                                    <div>
                                                                    <Typography variant="body1" className="product-description">
                                                                        {expandedDescriptions[item.refId] ? item.description : item.description?.length > 50 ? `${item.description.slice(0, 50)}...` : item.description}
                                                                        {item.description?.length > 50 && (
                                                                            <span onClick={() => toggleReadMore(item.refId)} style={{ cursor: 'pointer' }} className="read-more-home">
                                                                                {expandedDescriptions[item.refId] ? ' Read Less' : ' Read More'}
                                                                            </span>
                                                                        )}
                                                                    </Typography>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                            {Object.keys(getStore).length > 0 && (getStore.active == "true" || getStore.active == true) && isStoreOpen(getStore.timings) && 
                                                            <div className="product-actions">
                                                                <div className="chips-set">
                                                                    <div>
                                                                        {item.optionGroups.length > 0 && <Chip label="customize" variant="outlined" size="small" sx={{
                                                                            borderColor: 'green',
                                                                            color: 'green',
                                                                        }} />}
                                                                    </div>
                                                                    <div className="chips-not-avelable">
                                                                        {(item.available != 1 || item.available != true) && <Chip label="Not available" color="error" variant="outlined" size="small" />}
                                                                    </div>
                                                                </div>
                                                                <div className="addcart-in-de-button">
                                                                    {isProductInCart(item) ? (<>
                                                                        <IconButton className="remove-button">
                                                                            <RemoveIcon onClick={()=> dispatch(decreaseQtyFromCatalogue({refId: item.refId}))}
                                                                            />
                                                                        </IconButton>
                                                                        <Typography variant="body1" style={{ width: '30px', textAlign: 'center', backgroundColor: '#EFEFEF', borderRadius: '10px', margin:'0px 4px', padding: '8px 0px', }}>{getProductQuantityInCart(item)}</Typography>
                                                                        <IconButton  color="primary"
                                                                            className="add-button"
                                                                            onClick={()=> addProductToCart(item, item.price ? item.price : item?.optionGroups.find((el) => el.item_ref_ids == item.refId)?.options[0].price)}>
                                                                            <AddIcon />
                                                                        </IconButton>
                                                                        </>) : (
                                                                        <IconButton
                                                                            color="primary"
                                                                            className="add-button"
                                                                            onClick={()=>addProductToCart(item, item.price ? item.price : item?.optionGroups.find((el) => el.item_ref_ids == item.refId)?.options[0].price)}
                                                                        >
                                                                            <AddIcon />
                                                                        </IconButton>
                                                                    )}
                                                                </div>
                                                            </div>}
                                                        </Paper>
                                                    </Grid>
                                                ))
                                            }
                                            {category.subCategories.length > 0 &&
                                                category.subCategories.map((subcategory, index) => (
                                                    subcategory.items.length > 0 && (
                                                        <React.Fragment key={index}>
                                                            <h3
                                                                className="divider line one-line category-header title2"
                                                                ref={el => categoryRefs.current[subcategory.refId] = el}
                                                            >
                                                               <span>{subcategory.title}</span> 
                                                            </h3>
                                                            {subcategory.items.map((item, index) => (
                                                                <Grid item xs={12} sm={6} md={6} lg={3} key={index} className="item-cart-category">
                                                                    <Paper className="product-card">
                                                                        <div className="product-box-wc">
                                                                        <div className="pro-contentimg">
                                                                                <img
                                                                                    src={item.imgUrl && item.imgUrl !== null && item.imgUrl !== "" ? item.imgUrl : dummy}
                                                                                    onError={(e) => {
                                                                                        e.target.src = dummy;
                                                                                      }}
                                                                                    alt="Product Image"
                                                                                    className="product-image"
                                                                                />
                                                                            </div>
                                                                            <div className="pro-contentbox">
                                                                                <div className="d-flex align-items-center mb-2">
                                                                                    <div className={item.foodType == 1 ? "veg-flag" : "non-vegflag"}>
                                                                                        <span></span>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <Typography variant="h4" className="pro-title">
                                                                                        {item.title}
                                                                                    </Typography>
                                                                                </div>
                                                                                <div>
                                                                                    <Typography variant="body1" className="product-price">
                                                                                        {getStore?.currencysymbol}  {item.price ? item.price : item?.optionGroups.find((el) => el.item_ref_ids == item.refId)?.options[0].price}
                                                                                    </Typography>
                                                                                </div>
                                                                                <div>
                                                                                <Typography variant="body1" className="product-description">
                                                                                    {expandedDescriptions[item.refId] ? item.description : item.description?.length > 50 ? `${item.description.slice(0, 80)}...` : item.description}
                                                                                    {item.description?.length > 50 && (
                                                                                        <span onClick={() => toggleReadMore(item.refId)} style={{ cursor: 'pointer' }} className="read-more-home">
                                                                                            {expandedDescriptions[item.refId] ? ' Read Less' : ' Read More'}
                                                                                        </span>
                                                                                    )}
                                                                                </Typography>
                                                                                </div>
                                                                               
                                                                            </div>
                                                                            
                                                                        </div>
                                                                        
                                                                        {Object.keys(getStore).length > 0 && (getStore.active == "true" || getStore.active == true) && isStoreOpen(getStore.timings) && <div className="product-actions">
                                                                            {/* {item.optionGroups.length > 0 && <Button
                                                                                variant="outlined"
                                                                                className="customize-button"
                                                                            >
                                                                                Customize
                                                                            </Button>} */}
                                                                            <div className="chips-set">
                                                                                <div>
                                                                                    {item.optionGroups.length > 0 && <Chip label="customize" variant="outlined" size="small" sx={{
                                                                                        borderColor: 'green',
                                                                                        color: 'green',
                                                                                    }} />}
                                                                                </div>
                                                                                <div className="chips-not-avelable">
                                                                                    {(item.available != 1 || item.available != true) && <Chip label="Not available" color="error" variant="outlined" size="small" />}
                                                                                </div>
                                                                            </div>
                                                                            <div className="addcart-in-de-button">
                                                                                {isProductInCart(item) ? (<>
                                                                                    <IconButton className="remove-button" >
                                                                                        <RemoveIcon onClick={()=> dispatch(decreaseQtyFromCatalogue({refId: item.refId}))} />
                                                                                    </IconButton>
                                                                                    <Typography variant="body1" style={{ width: '30px', textAlign: 'center', backgroundColor: '#EFEFEF', borderRadius: '10px', margin:'0px 4px', padding: '8px 0px', }}>{getProductQuantityInCart(item)}</Typography>
                                                                                    <IconButton  color="primary"
                                                                                        className="add-button"
                                                                                        onClick={()=> addProductToCart(item, item.price ? item.price : item?.optionGroups.find((el) => el.item_ref_ids == item.refId)?.options[0].price)}>
                                                                                        <AddIcon />
                                                                                    </IconButton>
                                                                                    </>) : (
                                                                                    <IconButton
                                                                                        color="primary"
                                                                                        className="add-button"
                                                                                        onClick={()=>addProductToCart(item, item.price ? item.price : item?.optionGroups.find((el) => el.item_ref_ids == item.refId)?.options[0].price)}
                                                                                    >
                                                                                        <AddIcon />
                                                                                    </IconButton>
                                                                                )}
                                                                            </div>
                                                                            


                                                                            {/* <IconButton
                                                                                color="primary"
                                                                                className="add-button"
                                                                                onClick={()=>addProductToCart(item, item.price ? item.price : item?.optionGroups.find((el) => el.item_ref_ids == item.refId)?.options[0].price)}
                                                                            >
                                                                                <AddIcon />
                                                                            </IconButton>
                                                                            <IconButton style={{ color: '#fff', backgroundColor: '#000' }} >
                                                                                <RemoveIcon />
                                                                            </IconButton>
                                                                            <Typography variant="body1" style={{ width: '30px', textAlign: 'center', backgroundColor: '#EFEFEF', borderRadius: '10px', margin:'0px 10px', padding: '10px 0px', }}>25</Typography>
                                                                            <IconButton  color="primary"
                                                                                className="add-button"
                                                                                onClick={()=>addProductToCart(item, item.price ? item.price : item?.optionGroups.find((el) => el.item_ref_ids == item.refId)?.options[0].price)}>
                                                                                <AddIcon />
                                                                            </IconButton> */}
                                                                        </div>}
                                                                    </Paper>
                                                                </Grid>
                                                            ))}
                                                        </React.Fragment>
                                                    )
                                                ))
                                            }
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Box>
            { openDialog && <DialogComp openDialog={openDialog} setOpenDialog={setOpenDialog} dialogItem={dialogItem} addProductToCart={addProductToCart} />
            }
            <SearchModal openSearchDialog={openSearchDialog} setOpenSearchDialog={setOpenSearchDialog} searchItems={searchItems} addProductToCart={addProductToCart} isStoreOpen={isStoreOpen}/>
            <ScrollBottomToTop />
            <StoreLocationPopup
                open={showLocationPopup}
                onClose={() => setShowLocationPopup(false)} // Close the pop-up
                onSelectLocation={handleLocationSelect} // Handle location selection
                locations={stores}
            />
            {/* <Footer /> */}
        </div>
    );
}

export default HomePage;
