import React, { useState } from "react";
import dummy from "../../assets/offerImage.png";
import "./OfferScroller.css";

const OfferScroller = ({ offers }) => {
    const [expandedDescriptions, setExpandedDescriptions] = useState({});

    const toggleReadMore = (index) => {
        setExpandedDescriptions((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    return (
        <div>
            <h2 className="offers-title divider line one-line">
                <span>PROMOS</span>
            </h2>
            <div className="offers-scroller">
                {offers.map((offer, index) => {
                    const isExpanded = expandedDescriptions[index];
                    const shouldShowReadMore = offer.offerdescription.length > 80;

                    return (
                        <div key={index} className="offer-card">
                            <img src={dummy} alt="offer-image" className="offer-image" />
                            <p className="offer-title">{offer.offername}</p>
                            <p className="offer-description">
                                {isExpanded
                                    ? offer.offerdescription
                                    : `${offer.offerdescription.slice(0, 80)}${shouldShowReadMore ? "..." : ""}`}
                                {shouldShowReadMore && (
                                    <span
                                        onClick={() => toggleReadMore(index)}
                                        className="read-more-offer"
                                    >
                                        {isExpanded ? " Read Less" : " Read More"}
                                    </span>
                                )}
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default OfferScroller;
